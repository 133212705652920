import Swiper, { Navigation, Pagination, EffectFade, Mousewheel } from 'swiper'
import _throttle from "lodash.throttle";
import eventBus from "../utils/eventBus";

const sliderElements = document.querySelectorAll('.c-slider')

if (sliderElements) {
  sliderElements.forEach(sliderEl => {
    let slider;
    let baseOptions = {
      init: false,
      modules: [Navigation, Pagination, EffectFade, Mousewheel],
      pagination: {
        el: sliderEl.querySelector('.c-slider__pagination'),
        type: 'fraction',
      },
      navigation: {
        nextEl: sliderEl.querySelector('.c-slider__button--next'),
        prevEl: sliderEl.querySelector('.c-slider__button--prev'),
      }
    }
    const { sliderSettings } = sliderEl.dataset
    let additionalSettings = {}

    if (sliderSettings) {
      additionalSettings = JSON.parse(sliderSettings)
    }

    const enableSlider = () => {
      slider = new Swiper(sliderEl.querySelector('.c-slider__container'), { ...baseOptions, ...additionalSettings})

      if (sliderEl.classList.contains('c-slider--offgrid')) {
        // Get to know which slides are currently in view
        slider.on('init slideChange', (s) => {
          let slidesPerView = s.passedParams.slidesPerView

          if (Object.keys(s.passedParams.breakpoints).includes(s.currentBreakpoint)) {
            slidesPerView = s.passedParams.breakpoints[s.currentBreakpoint].slidesPerView
          }

          const currentSlideSelection = Array.from(s.slides).slice(s.realIndex, s.realIndex + slidesPerView)
          const restSlides = Array.from(s.slides).filter(slide => !currentSlideSelection.includes(slide))
          const viewdSlides = Array.from(s.slides).filter((slide,index) => index < s.activeIndex)


          currentSlideSelection.forEach(slideEl => slideEl.classList.add(['c-slider__item--current']))
          currentSlideSelection.forEach(slideEl => slideEl.classList.remove(['c-slider__item--viewed']))
          restSlides.forEach(slideEl => slideEl.classList.remove('c-slider__item--current'))
          viewdSlides.forEach(slideEl => slideEl.classList.add('c-slider__item--viewed'))
        });
      }

      slider.on('slideChange', (s) => {
        eventBus.emit('empact-slide-changed', s.realIndex)
      })
    }

    const handleMobileSlider = () => {
      const mq = window.matchMedia('(min-width: 1024px)')

      if (mq.matches === true) {
        if (slider !== undefined) {
          slider.destroy(true, true);
        }
        sliderEl.classList.add('c-slider--is-grid');
      } else if (mq.matches === false) {
        enableSlider()
        slider.init()
        sliderEl.classList.remove('c-slider--is-grid');
      }
    }

    if (additionalSettings['mobileOnly'] === true) {
      handleMobileSlider()

      window.addEventListener('resize', _throttle(handleMobileSlider, 500))
    } else {
      enableSlider()
      slider.init()
    }
  })
}
