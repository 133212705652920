import _throttle from 'lodash.throttle'

const navBtn = document.querySelector('.c-mobile-header__toggle');
const nav = document.querySelector('.c-mobile-header__menu');
const header = document.querySelector('.c-header');
const logo = document.querySelector('.c-header__logo');

const navToggle = () => {

  nav.classList.toggle('is-active');
  navBtn.classList.toggle('is-active');
  header.classList.toggle('is-active');
  logo.classList.toggle('is-active');

}

if(navBtn) {
  navBtn.addEventListener('click',navToggle);
}



const storeHeaderHeight = (height) => {
  const box = header.getBoundingClientRect()
  document.documentElement.style.setProperty('--page-header-height', `${box.height}px`)
}

window.addEventListener('resize', _throttle(() => storeHeaderHeight(), 500))
window.addEventListener('DOMContentLoaded', storeHeaderHeight, { once: true })




const hero = document.querySelector('.c-hero')

let scrollObject = {};

const scroll = () => {
  if (hero) {
    const heroBox = hero.getBoundingClientRect().height;
    const box = header.getBoundingClientRect().height;

    if (header) {

      scrollObject = {
        y: window.pageYOffset,
      };

      if (scrollObject.y > ((heroBox / 2) - box)) {
        header.classList.add('is-scrolled');
      } else {
        header.classList.remove('is-scrolled');
      }
    }
  } else {
    const box = header.getBoundingClientRect().height;

    if (header) {

      scrollObject = {
        y: window.pageYOffset,
      };

      if (scrollObject.y > box) {
        header.classList.add('is-scrolled');
      } else {
        header.classList.remove('is-scrolled');
      }
    }
  }
};

window.addEventListener('scroll', scroll);

