import lottie from 'lottie-web';
import scrollMonitor from 'scrollmonitor';

const hover = () => {
    const playerElements = document.querySelectorAll('.u-lottie.u-lottie--hover');
    const mq = window.matchMedia('(min-width: 1024px)');


    if (playerElements) {
        Array.prototype.forEach.call(playerElements, element => {

            const player = lottie.loadAnimation({
                container: element,
                renderer: 'svg',
                //loop: mq.matches,
                loop: false,
                autoplay: false,
                animationData: JSON.parse(
                    element.getAttribute('data-lottie-animation'),
                ),
            });
            player.setSpeed(0.6);

            const elWatcher = scrollMonitor.create(element, { top: -200 });
            elWatcher.enterViewport(() => {
                player.play();
            });

            /*
            if(mq.matches !== true) {
                const elWatcher = scrollMonitor.create(element, { top: -200 });
                elWatcher.enterViewport(() => {
                    player.play();
                });

            } else {
                element.parentNode.parentNode.addEventListener('mouseenter', () => {
                    player.play();
                });

                element.parentNode.parentNode.addEventListener('mouseleave', () => {
                    player.stop();
                    player.goToAndStop(0);
                });

            }
            */
        });
    }
};

hover();

const scrollLottie = () => {

    const playerElm = document.querySelectorAll('.u-lottie.u-lottie--loop');

    if (playerElm) {
        Array.prototype.forEach.call(playerElm, element => {

            const player = lottie.loadAnimation({
                container: element,
                renderer: 'svg',
                loop: false,
                autoplay: false,
                animationData: JSON.parse(
                    element.getAttribute('data-lottie-animation'),
                ),
            });
            player.setSpeed(0.6);

            const elWatcher = scrollMonitor.create(element, { top: -200 });
            elWatcher.enterViewport(() => {
                player.play();
            });

        });
    }

}

scrollLottie();