const heroAnimation = () => {
    const heroVideos = document.querySelectorAll('[data-video-delay]');
    heroVideos.forEach(video => {

        const hero = video.closest('.c-hero');

        const { videoDelay } = video.dataset;
        console.log(video,videoDelay)

        video.addEventListener('timeupdate', function () {

            if(video.currentTime > videoDelay) {
                hero.classList.add('is-animated');
            }

        })
    });
}



window.addEventListener('DOMContentLoaded', heroAnimation);