import Plyr from 'plyr';

const autoplay = () => {
    Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
        get() {
            return !!(
                this.currentTime > 0 &&
                !this.paused &&
                !this.ended &&
                this.readyState > 2
            );
        },
    });

    const videoElement = document.querySelector('#introVideo');
    const imageElement = document.querySelector('#introImage');
    if (videoElement && imageElement) {
        if (!videoElement.playing) {
            videoElement.classList.add('low-powermode');
            imageElement.classList.remove('low-powermode');
        }

        document.querySelector('body').addEventListener('touchstart', () => {
            if (!videoElement.playing) {
                videoElement.play();
            }
        });

        videoElement.addEventListener('play', () => {
            videoElement.classList.remove('low-powermode');
            imageElement.classList.add('low-powermode');
        });
    }
};

autoplay();


const video = () => {
    const videos = document.querySelectorAll('.u-plyr');

    videos.forEach(video => {

        const controls = [
            'play-large',
            'play',
            'progress',
            'current-time',
            'mute',
            'volume',
            'fullscreen',
        ]

        const player = new Plyr(video, {
            controls,
            hideControls: true,
        });
    });

};


window.addEventListener('DOMContentLoaded', video);