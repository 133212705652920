import scrollMonitor from 'scrollmonitor';

const animation = (widget = null) => {

    const elements = document.querySelectorAll('.c-graphic-teaser__icon');
    elements.forEach(element => {

        const elWatcher = scrollMonitor.create(element, { top: -200 });
        elWatcher.enterViewport(() => {
            element.classList.add('c-graphic-teaser__icon--animate');
        });

    })
}
animation();